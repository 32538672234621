@import '../../scss/theme-bootstrap';

.elc-product-brief {
  height: 100%;
  position: relative;
  @include breakpoint($landscape-up) {
    padding-bottom: 50px;
  }
  .elc-product {
    &-images-wrapper {
      aspect-ratio: 1;
      position: relative;
      padding: 50%;
      @include breakpoint($landscape-up) {
        min-width: 308px;
        min-height: 308px;
      }
      img.elc-img {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
      }
    }
    &-display-name-wrapper {
      display: inline-block;
      margin: 30px 0;
      width: 70%;
      #{$sd-grid-2-up-selector} {
        margin: 10px 0;
        @include breakpoint($landscape-up) {
          margin: 30px 0;
        }
      }
      .elc-product-display-name-link {
        font-family: $font--pretendard-semi-bold;
        font-size: 15px;
        font-weight: bold;
        line-height: get-line-height(16px, 18px);
        margin: 0;
        text-transform: capitalize;
        #{$sd-grid-2-up-selector} {
          font-size: 12px;
          line-height: get-line-height(12px, 16px);
          letter-spacing: 0.01rem;
          @include breakpoint($landscape-up) {
            font-size: 16px;
            line-height: get-line-height(16px, 18px);
            letter-spacing: initial;
          }
        }
        @include breakpoint($landscape-up) {
          font-size: 16px;
        }
      }
    }
    &-short-description-wrapper {
      font-family: $font--text-main;
      color: $color-black;
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 15px;
      text-transform: initial;
      #{$sd-grid-2-up-selector} {
        font-size: 12px;
        line-height: 16px;
        margin: 0 0 5px;
        @include breakpoint($landscape-up) {
          margin: 0 0 15px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    &-badge {
      #{$rdirection}: 15px;
      margin-inline-start: 17%;
      align-items: center;
      border-radius: 50%;
      border: 1px solid $color-black;
      color: $color-black;
      font-family: $font--text;
      font-size: 12px;
      height: 65px;
      line-height: 15px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 15px;
      width: 65px;
      display: flex;
      justify-content: center;
      overflow-wrap: anywhere;
      #{$sd-grid-2-up-selector} {
        font-family: $font--pretendard-bold;
        font-size: 9px;
        height: 40px;
        line-height: 1;
        top: 10px;
        width: 40px;
        #{$rdirection}: 25px;
        @include breakpoint($landscape-up) {
          font-family: $font--text;
          font-size: 12px;
          height: 65px;
          width: 65px;
          line-height: 1.1;
          #{$rdirection}: 15px;
          top: 15px;
        }
      }
    }
    &-prices-wrapper,
    &-prices-clickable-wrapper {
      display: inline-block;
      margin-top: 15px;
      text-align: $rdirection;
      vertical-align: top;
      width: 30%;
      #{$sd-grid-2-up-selector} {
        margin-top: 5px;
        @include breakpoint($landscape-up) {
          margin-top: 15px;
        }
      }
      .elc-product-prices-wrapper {
        width: 100%;
      }
      .elc-product-prices-wrapper,
      .elc-product-price-per-unit-row-wrapper {
        line-height: 1;
      }
      .elc-product-price-row-wrapper {
        margin: 0;
      }
      .elc-price-formatted-wrapper {
        .elc-price-formatted {
          color: $color-black;
          font-family: $font--pretendard-semi-bold;
          font-size: 15px;
          line-height: get-line-height(16px, 18px);
          margin: 0;
          #{$sd-grid-2-up-selector} {
            font-size: 12px;
            line-height: get-line-height(12px, 16px);
            @include breakpoint($landscape-up) {
              font-size: 16px;
              line-height: get-line-height(16px, 18px);
            }
          }
          @include breakpoint($landscape-up) {
            font-size: 16px;
          }
        }
        .elc-product-price {
          margin-#{$ldirection}: 10px;
          font-family: $font--pretendard-semi-bold;
          color: $red;
        }
        .elc-product-price-per-unit {
          display: none;
        }
      }
    }
    &-full-cta-wrapper {
      box-shadow: none;
      position: relative;
      width: 100%;
      @include breakpoint($small-landscape-up) {
        position: absolute;
        bottom: 0;
      }
      @include breakpoint($landscape-up) {
        padding: 0;
      }
      .elc-product-notify-me-button,
      .elc-add-to-bag-button {
        @include elc-button;
        @include elc-button--dark;
        height: 46px;
        width: 100%;
        font-size: 14px;
        padding: 15px 16px;
        max-width: 184px;
        &-disabled:hover,
        &-disabled {
          background: $color-white;
          border-color: $color-grey;
          color: $color-grey;
        }
        #{$sd-grid-2-up-selector} {
          height: 36px;
          font-size: 12px;
          padding: 9px;
          max-width: 130px;
          min-width: 120px;
          letter-spacing: 0.05rem;
          @include breakpoint($landscape-up) {
            height: 46px;
            width: 100%;
            font-size: 14px;
            padding: 15px 16px;
            max-width: 184px;
            min-width: 160px;
            letter-spacing: 1.5px;
          }
        }
      }
    }
    &-shade-picker {
      min-height: auto;
      &-slider {
        padding: 0 10%;
        @include breakpoint($landscape-up) {
          padding: 0;
        }
      }
      .elc-shade-image {
        border-bottom: 3px solid $color-white;
        border-radius: 50%;
        height: 36px;
        overflow: hidden;
        padding: 0;
        width: 36px;
        border: 1px solid transparent;
        outline: none;
        #{$sd-grid-2-up-selector} {
          height: 25px;
          width: 25px;
          @include breakpoint($landscape-up) {
            height: 36px;
            width: 36px;
          }
        }
        &:hover,
        &.elc-shade-image-selected {
          border: 1px solid $color-black;
        }
        &::after {
          display: none;
        }
        .elc-absolute-shade-wrapper {
          position: relative;
          border: 4px solid $color-white;
        }
      }
      .elc-product-shade-picker-slider {
        .slick-list {
          .slick-slide {
            margin: 0 3px;
          }
        }
        .elc-slider-arrow-wrapper {
          &.slick-arrow {
            top: 24px;
            @include breakpoint($landscape-up) {
              top: 10px;
            }
            &.slick-prev {
              #{$ldirection}: -30px;
            }
            &.slick-next {
              #{$rdirection}: -30px;
            }
          }
          .elc-directional-icon {
            background-color: $color-black;
            height: 16px;
            width: 16px;
          }
        }
      }
      .elc-grid-column {
        padding: 0;
      }
    }
    &-name-wrapper {
      margin: 0;
    }
    &-rating-wrapper {
      justify-content: flex-start;
      .elc-rating {
        margin: 0;
      }
      .elc-reviews-number span::after,
      .elc-average-rating-text {
        display: none;
      }
    }
  }
  .elc-size-picker-box,
  .elc-size-picker-box-selected {
    #{$sd-grid-2-up-selector} {
      @include swap_direction(margin, 5px 5px 0 0);
      min-width: unset;
      padding: 9px 10px;
      font-size: 12px;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 10px 5px 8px 0);
        min-width: 87px;
        padding: 15px 10px;
        font-size: 14px;
      }
    }
  }
  .elc-media-asset {
    .elc-product-images-wrapper {
      min-height: initial;
      min-width: initial;
    }
  }
  a.elc-clickable-wrapper {
    text-decoration: none;
  }
  .elc-shade-swatches-wrapper {
    margin-bottom: 10px;
    .elc-single-shade-wrapper {
      padding: 0;
      margin: 0;
      flex-direction: column;
      align-items: flex-start;
      .elc-shade-icon {
        width: 28px;
        height: 28px;
        margin-bottom: 5px;
      }
    }
    .elc-product-shade-picker-title,
    .elc-single-shade-wrapper p {
      @include swap_direction(margin, 0 0 0 5px);
      font-family: $font--text-main;
      font-size: 11px;
      color: $color-black;
      line-height: get-line-height(11px, 15px);
    }
    .elc-single-shade-wrapper p {
      margin: 0;
    }
  }
  .elc-size-picker {
    &-box,
    &-box-selected {
      @include swap_direction(margin, 10px 5px 8px 0);
      background: $color-white;
      border-radius: 0;
      border: 1px solid $color-darker-grey;
      color: $color-black;
      font-family: $font--pretendard-semi-bold;
      font-size: 14px;
      line-height: 1;
      min-width: 87px;
      padding: 15px 10px;
      width: auto;
      text-transform: uppercase;
      &:hover {
        background: $color-darker-grey;
        color: $color-white;
      }
    }
    &-box-selected {
      border-color: $color-black;
      color: $color-black;
    }
    &-wrapper {
      > div {
        display: none;
        &:last-child {
          display: block;
          margin: 0 0 5px;
          @include breakpoint($landscape-up) {
            margin: 0 0 30px;
          }
        }
        .elc-slider-view-wrapper {
          padding: 0;
        }
      }
      .elc-selected-size {
        font-family: $font--text-main;
        color: $color-black;
        font-size: 16px;
        line-height: 20px;
        width: auto;
        font-weight: normal;
        #{$sd-grid-2-up-selector} {
          font-size: 12px;
          line-height: 1.2;
          @include breakpoint($landscape-up) {
            font-size: 16px;
            line-height: get-line-height(16px, 20px);
          }
        }
      }
      .slick-slide {
        width: auto !important;
      }
      .slick-track {
        justify-content: flex-start;
        flex-wrap: wrap;
      }
    }
  }
  .elc-rating-component {
    margin-bottom: 15px !important;
  }
  .elc-product-grid-wrapper & {
    @include breakpoint($small-landscape-up) {
      padding-bottom: 28px;
    }
    @include breakpoint($landscape-up) {
      padding-bottom: 50px;
    }
  }
}

.elc-responsive-modal-mask {
  .elc-product-notify-me-pop-up-wrapper,
  .elc-product-notify-me-confirmation-wrapper {
    min-height: 350px;
    @include breakpoint($medium-up) {
      width: 544px;
      min-height: 408px;
    }
    .elc-product-notify-me-pop-up-close-button {
      margin: 12px;
      padding: 0;
      background-color: unset;
      &:focus {
        outline: 0;
      }
      &-icon {
        background-color: $color-primary-400;
        height: 28px;
        width: 28px;
      }
    }
    .elc-product-notify-me-content {
      padding: 30px 15px 0;
      @include breakpoint($medium-up) {
        padding: 25px 30px 0;
      }
      .elc-product-notify-me-title-label {
        font-size: 20px;
      }
      .elc-product-notify-me-description-label {
        font-family: $font--text;
        margin: 15px 0;
      }
    }
    .elc-product-notify-me-form-wrapper {
      width: 100%;
      display: inline-block;
      @include swap_direction(margin, 0 20px 0 0);
      @include breakpoint($medium-up) {
        width: 300px;
      }
      .elc-input-field,
      .elc-input-error,
      .elc-floating-label-string {
        font-size: 12px;
        text-transform: uppercase;
        color: $color-primary-400;
      }
      .elc-input-field {
        height: 46px;
        border-radius: 0;
        border: 1px solid $color-primary-400;
        padding: 0 14px;
      }
      .elc-input-error {
        margin: 7px 0 0;
        padding: 0;
        p {
          text-transform: none;
          color: $red;
          font-size: 13px;
        }
      }
    }
    .elc-product-notify-me {
      &-notice-wrapper {
        position: absolute;
      }
      &-gdpr-notice-label {
        margin: 5px 0;
        @include breakpoint($medium-up) {
          margin: 10px 5px 5px;
        }
        a {
          text-decoration: underline;
        }
      }
      &-gdpr-read-confirmation {
        color: $red;
        margin-#{$rdirection}: 10px;
        font-size: 13px;
      }
      &-info {
        position: absolute;
        bottom: 20px;
        &:hover {
          .elc-product-gdpr-popup {
            visibility: visible;
          }
        }
      }
      &-info-your-data {
        padding: 2px 5px;
      }
      &-info-button {
        background: transparent;
        padding: 0;
        min-width: initial;
      }
      &-confirmation-content {
        padding: 35px 0 0;
        @include breakpoint($medium-up) {
          max-width: 90%;
        }
      }
      &-confirmation-title {
        margin: 0 0 15px;
      }
      &-confirmation-continue-button {
        @include swap_direction(margin, 0 15px 15px 0);
        #{$rdirection}: 0;
        height: 40px;
      }
      &-confirmation-close-button {
        background: none;
        &:focus {
          outline: 0;
        }
        &-icon {
          background-color: $color-primary-400;
          height: 28px;
          width: 28px;
        }
      }
    }
    .elc-product-gdpr-popup {
      bottom: 35px;
      position: absolute;
      padding: 13px;
      visibility: hidden;
      background-color: $color-grey;
      border-radius: 6px;
    }
    .elc-product-gdpr-popup-information {
      color: $white;
      a {
        text-decoration: underline;
        color: $white;
      }
    }
    .elc-product-notify-me-submit-button {
      @include elc-button;
      @include elc-button--light;
      overflow: hidden;
      font-size: 15px;
      min-height: 45px;
      margin-top: 65px;
      padding: 15px;
      width: 100%;
      float: #{$rdirection};
      @include breakpoint($medium-up) {
        width: 132px;
        margin: 0;
      }
    }
  }
}

.sd-grid-skeleton-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 360px;
}

.sd-grid-skeleton-items {
  display: grid;
  grid-auto-flow: dense;
  gap: 0px;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(33.3333%, 1fr));
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(33.3333%, 1fr));
  }
}

.sd-grid-skeleton {
  padding: 0;
  @include breakpoint($landscape-up) {
    padding: initial;
  }
  .skeleton-item {
    padding: 0;
    @include breakpoint($landscape-up) {
      padding: 8px;
    }
    grid-column-end: span 1;
    grid-row-end: span 1;
    width: 100%;

    @media (min-width: 768px) {
      grid-column-end: span 1;
      grid-row-end: span 1;
      width: auto;
    }

    .has-alt-image {
      img.elc-img {
        height: auto;
        width: 100%;
      }
      .elc-img--alt {
        display: none;
      }
      &:hover {
        .elc-img {
          display: none;
        }
        .elc-img--alt {
          display: block;
        }
      }
    }
  }
}

// Moved from React inline style.
.elc-size-picker-container {
  .slick-track {
    display: flex;
    width: inherit !important;
  }
}
